import React from 'react';

export const useSiteUrls = () => {
  /**
   * Return
   */
  return {
    siteUrl: process.env.REACT_APP_PUBLIC_URL,
    appUrl: process.env.REACT_APP_BASE_URL,
  };
};
