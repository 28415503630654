import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSiteUrls } from '../hooks/use-site-urls';

const LearningHub = () => {
  /**
   * Hooks
   */
  const { siteUrl } = useSiteUrls();
  useEffect(() => {
    navigate(`${siteUrl}/insights`);
  }, []);

  return null;
};

export default LearningHub;
